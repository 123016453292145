var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 font-size-18"},[_vm._v(_vm._s(_vm.t("Sponsored")))])])])]),_c('div',{staticClass:"row"},_vm._l((_vm.statData),function(stat){return _c('div',{key:stat.icon,staticClass:"col-md-4"},[_c('Stat',{attrs:{"icon":stat.icon,"title":stat.title,"value":stat.value}})],1)}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.table.errored)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.t( "We apologize, we are unable to retrieve information at this time. Please try again later." ))+" ")]):(_vm.table.empty)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.t("No records found."))+" ")]):_c('div',{staticClass:"table-responsive"},[(_vm.table.loading)?_c('vcl-list',{staticClass:"col-lg-6"}):_c('table',{staticClass:"table table-centered table-nowrap"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th'),_c('th',[_vm._v("Usuário")]),_c('th',[_vm._v("Contatos")]),_c('th',[_vm._v("Tabuleiros")])])]),_c('tbody',_vm._l((_vm.table.body),function(td,index){return _c('tr',{key:index},[_c('td',[(td.avatar)?_c('img',{staticClass:"rounded-circle header-profile-user",staticStyle:{"width":"50px","height":"50px"},attrs:{"src":td.avatar,"alt":""}}):_c('img',{staticClass:"rounded-circle header-profile-user",staticStyle:{"width":"50px","height":"50px"},attrs:{"src":'https://ui-avatars.com/api/?background=2a5ff8&color=fff&name=' +
                        td.name,"alt":""}})]),_c('td',{staticClass:"notranslate"},[_vm._v(" "+_vm._s(td.username)),_c('br'),_vm._v(" "+_vm._s(td.name)),_c('br')]),_c('td',[_vm._v(" "+_vm._s(td.email)),_c('br'),_vm._v(" "+_vm._s(td.cellphone)+" "),_c('a',{attrs:{"target":"_blank","href":'https://api.whatsapp.com/send?phone=' +
                        td.cellphone
                          .replace('+', '')
                          .replace('.', '')
                          .replace(' ', '')
                          .replace('-', '')}},[_c('i',{staticClass:"bx bxl-whatsapp font-size-24 align-middle text-success"})])]),_c('td',[_c('div',{staticClass:"d-flex"},_vm._l((td.boards),function(boa,i){return _c('div',{key:i,staticClass:"board-position",class:[boa.positions == 0 ? 'disabled' : '']},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[boa.positions == 0 ? 'disabled' : ''],staticStyle:{"width":"45px"},attrs:{"src":require('@/assets/images/boards/active.svg')}}),_c('sup',{staticClass:"board-total badge badge-light"},[_vm._v(_vm._s(boa.positions))]),_c('span',{staticClass:"board-name"},[_vm._v(_vm._s(boa.name))])])}),0)])])}),0)])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }